import { Card, Typography, styled } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

export const StyledCardHeadline = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: '#2B3E60',
    position: 'relative',
    paddingBottom: '8px',
    marginBottom: '8px',
    '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '40px',
        height: '2px',
        backgroundColor: '#ddd',
    },
}));

export const StyledCardContent = styled(Typography)(({ theme }) => ({
    color: '#F9C000',
    marginTop: theme.spacing(1),
}));