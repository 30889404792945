import React from "react";
import { GlobalStyles } from "@mui/material";

const globalStyles = (
    <GlobalStyles
        styles={{
            body: {
                margin: 0,
                padding: 0,
                fontFamily: '"Quicksand", "Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: '13px',
                color: '#555',
            },
            '*': {
                boxSizing: 'border-box',
                wordWrap: 'break-word',
            },
            'button': {
                fontFamily: 'inherit !important',
            },
            'p, span, li, a, div, td': {
                fontFamily: 'inherit !important',
                fontSize: '13px !important',
                lineHeight: '1.5 !important',
                '@media (min-width: 768px)': {
                    fontSize: '15px !important',
                },
            },
            'h1, h2, h3, h4, h5, h6': {
                fontFamily: 'inherit !important',
                lineHeight: 1.2,
                marginTop: 0,
            },
            h1: {
                fontSize: '20px',
                '@media (min-width: 768px)': {
                    fontSize: '24px',
                },
            },
            h2: {
                fontSize: '18px',
                '@media (min-width: 768px)': {
                    fontSize: '22px',
                },
            },
            h3: {
                fontSize: '16px',
                '@media (min-width: 768px)': {
                    fontSize: '20px',
                },
            },
            h4: {
                fontSize: '15px',
                '@media (min-width: 768px)': {
                    fontSize: '18px',
                },
            },
            'th': {
                backgroundColor: '#eceff1',
            },
            'th, td': {
                border: '1px solid #ddd',
                fontFamily: 'inherit !important',
            },
            'tbody tr': {
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            },
        }}
    />
);

export default globalStyles;