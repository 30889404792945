import React, { useState } from "react";
import { TextField as MuiTextField, styled, InputAdornment, IconButton } from "@mui/material";
import Icon from "../vectors/bundle";

const StyledTextField = styled(MuiTextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        '& fieldset': {
            borderColor: '#ddd',
        },
        '&:hover fieldset': {
            borderColor: '#bbb',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#aaa',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '15px',
        fontSize: '13px',
        textAlign: 'center',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '&[type=number]': {
            MozAppearance: 'textfield',
        },
    },
    '& .MuiInputLabel-outlined': {
        fontSize: '13px',
    },
});

const NumField = ({
    min,
    max,
    step = 1,
    decimalPlaces = 0,
    onValueChange,
    value,
    onChange,
    InputProps,
    ...props
}) => {
    const [internalValue, setInternalValue] = useState(value?.toString() || '');

    const handleChange = (event) => {
        const newValue = event.target.value;
        let numValue = parseFloat(newValue);

        if (!isNaN(numValue)) {
            // Apply min and max constraints
            if (min !== undefined) numValue = Math.max(min, numValue);
            if (max !== undefined) numValue = Math.min(max, numValue);

            // Round to specified decimal places
            numValue = Number(numValue.toFixed(decimalPlaces));

            setInternalValue(numValue.toString());
            if (onValueChange) onValueChange(numValue);
        } else {
            setInternalValue(newValue);
        }

        if (onChange) onChange(event);
    };

    const adjustValue = (adjustment) => {
        const currentValue = parseFloat(internalValue) || 0;
        let newValue = Number((currentValue + adjustment).toFixed(decimalPlaces));
        
        if (min !== undefined) newValue = Math.max(min, newValue);
        if (max !== undefined) newValue = Math.min(max, newValue);

        setInternalValue(newValue.toString());
        if (onValueChange) onValueChange(newValue);
    };

    return (
        <StyledTextField
            {...props}
            type="number"
            value={internalValue}
            onChange={handleChange}
            InputProps={{
                ...InputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={() => adjustValue(-step)} edge="start">
                            <Icon name="deleteBold" />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => adjustValue(step)} edge="end">
                            <Icon name="addBold" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            inputProps={{
                ...props.inputProps,
                min,
                max,
                step,
            }}
        />
    );
};

export { NumField };