/*
    This page is to display the analysis data of the matches that the user has saved.
    The system will load the 5 most recent matches sorted by createTime (desc).
*/

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";

import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    IconButton,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

import Layout from "../theme/layout";
import Icon from "../theme/vectors/bundle";
import SlidePanel from "../theme/partials/slidepanel";
import { TextField } from "../theme/inputs/textfields";

const StatisticsPage = () => {
    const [stats, setStats] = useState({
        overall: {
            goodShotsPercentage: 0,
            goodOffensesPercentage: 0,
            pottedPercentage: 0,
            avgTimePerRack: 0,
            avgShotsPerTurn: 0,
            avgShotsPerRack: 0,
        },
        latest: {
            goodShotsPercentage: 0,
            goodOffensesPercentage: 0,
            pottedPercentage: 0,
            avgTimePerRack: 0,
            avgShotsPerTurn: 0,
            avgShotsPerRack: 0,
        },
    });

    useEffect(() => {
        fetchRecentMatches();
    }, []);

    const fetchRecentMatches = async () => {
        try {
            const matchesRef = collection(db, "Matches");
            const q = query(
                matchesRef,
                orderBy("createTime", "desc"),
                limit(5)
            );
            const querySnapshot = await getDocs(q);

            let totalGoodShots = 0,
                totalShots = 0,
                totalGoodOffenses = 0,
                totalOffenses = 0;
            let totalPottedBalls = 0,
                totalBalls = 0,
                totalTime = 0,
                totalRacks = 0,
                totalTurns = 0;

            let allTurns = [];
            let latestMatch = null;
            let index = 0;

            querySnapshot.forEach((doc) => {
                const match = doc.data();
                if (index == 0) {
                    latestMatch = match;
                }

                match.racks.forEach((rack) => {
                    totalTime += rack.duration || 0;
                    totalRacks++;
                    totalTurns += rack.turns.length;

                    rack.turns.forEach((turn) => {
                        allTurns.push(turn);

                        totalGoodShots +=
                            turn.goodOffense +
                            turn.goodDefense +
                            turn.goodCounter +
                            turn.goodBreak;
                        totalShots +=
                            turn.goodOffense +
                            turn.badOffense +
                            turn.goodDefense +
                            turn.badDefense +
                            turn.goodCounter +
                            turn.badCounter +
                            turn.goodBreak +
                            turn.badBreak;
                        totalGoodOffenses += turn.goodOffense;
                        totalOffenses += turn.goodOffense + turn.badOffense;
                        totalPottedBalls += turn.pottedBalls
                            ? turn.pottedBalls.length
                            : 0;
                    });

                    totalBalls += getBallCount(match.matchType);
                });

                index++;
            });

            const calculateStats = (
                goodShots,
                shots,
                goodOffenses,
                offenses,
                pottedBalls,
                balls,
                time,
                racks,
                turns
            ) => ({
                goodShotsPercentage: shots > 0 ? (goodShots / shots) * 100 : 0,
                goodOffensesPercentage:
                    offenses > 0 ? (goodOffenses / offenses) * 100 : 0,
                pottedPercentage: balls > 0 ? (pottedBalls / balls) * 100 : 0,
                avgTimePerRack: racks > 0 ? time / racks / 1000 / 60 : 0, // Convert to minutes
                avgShotsPerTurn: turns > 0 ? shots / turns : 0,
                avgShotsPerRack: racks > 0 ? shots / racks : 0,
            });

            const overallStats = calculateStats(
                totalGoodShots,
                totalShots,
                totalGoodOffenses,
                totalOffenses,
                totalPottedBalls,
                totalBalls,
                totalTime,
                totalRacks,
                totalTurns
            );

            let latestStats = {
                goodShotsPercentage: 0,
                goodOffensesPercentage: 0,
                pottedPercentage: 0,
                avgTimePerRack: 0,
                avgShotsPerTurn: 0,
                avgShotsPerRack: 0,
            };
            if (latestMatch && latestMatch.racks) {
                let latestGoodShots = 0,
                    latestShots = 0,
                    latestGoodOffenses = 0,
                    latestOffenses = 0;
                let latestPottedBalls = 0,
                    latestBalls = 0,
                    latestTime = 0,
                    latestRacks = latestMatch.racks.length,
                    latestTurns = 0;
                let allTurns = []; // Define allTurns array

                latestMatch.racks.forEach((rack) => {
                    latestTime += rack.duration || 0;
                    latestTurns += rack.turns.length;

                    rack.turns.forEach((turn) => {
                        allTurns.push(turn); // Collect all turns
                        latestGoodShots +=
                            turn.goodOffense +
                            turn.goodDefense +
                            turn.goodCounter +
                            turn.goodBreak;
                        latestShots +=
                            turn.goodOffense +
                            turn.badOffense +
                            turn.goodDefense +
                            turn.badDefense +
                            turn.goodCounter +
                            turn.badCounter +
                            turn.goodBreak +
                            turn.badBreak;
                        latestGoodOffenses += turn.goodOffense;
                        latestOffenses += turn.goodOffense + turn.badOffense;
                        latestPottedBalls += turn.pottedBalls
                            ? turn.pottedBalls.length
                            : 0;
                    });

                    latestBalls += getBallCount(latestMatch.matchType);
                });

                latestStats = calculateStats(
                    latestGoodShots,
                    latestShots,
                    latestGoodOffenses,
                    latestOffenses,
                    latestPottedBalls,
                    latestBalls,
                    latestTime,
                    latestRacks,
                    latestTurns
                );

                // Calculate streak performance
                const streakPerformance = calculateStreakPerformance(
                    allTurns,
                    latestMatch.matchType
                );
                latestStats.streakPerformance = streakPerformance;
                console.log("Streak performance: ", streakPerformance);
            }

            // Calculate overall streak performance
            const overallStreakPerformance = calculateStreakPerformance(
                allTurns,
                "9 balls"
            ); // Assuming 9 balls as default

            setStats({
                overall: {
                    ...overallStats,
                    streakPerformance: overallStreakPerformance,
                },
                latest: latestStats, // Keep the latest match stats as is
            });
        } catch (error) {
            console.error("Error fetching recent matches: ", error);
        }
    };

    const getBallCount = (matchType) => {
        switch (matchType) {
            case "9 balls":
                return 9;
            case "10 balls":
                return 10;
            default:
                return 9;
        }
    };

    const calculateStreakPerformance = (turns, matchType) => {
        let streaks = {};
        let currentStreak = 0;
        let totalStreaks = 0;
        const maxBalls = getBallCount(matchType);

        turns.forEach((turn) => {
            const pottedBalls = turn.pottedBalls || [];

            if (pottedBalls.length > 0) {
                currentStreak = pottedBalls.length;
                streaks[currentStreak] = (streaks[currentStreak] || 0) + 1;
                totalStreaks++;
            } else if (currentStreak > 0) {
                currentStreak = 0;
            }
        });

        let streakFrequency = {};
        for (let i = 1; i <= maxBalls; i++) {
            streakFrequency[i] = {
                frequency: (streaks[i] || 0) / totalStreaks || 0,
                class: getStreakClass(i),
            };
        }

        return streakFrequency;
    };

    const getStreakClass = (streakLength) => {
        if (streakLength === 1) return "I-";
        if (streakLength === 2) return "I";
        if (streakLength === 3) return "I+";
        if (streakLength === 4) return "H-";
        if (streakLength === 5) return "H";
        if (streakLength === 6) return "H+";
        if (streakLength === 7) return "G-";
        if (streakLength === 8) return "G";
        if (streakLength === 9) return "G+";
        if (streakLength >= 10) return "F-";
    };

    const getColorStyle = (percentage) => {
        // Convert percentage to a value between 0 and 1
        const value = percentage / 100;

        // Calculate the RGB components
        const red = Math.round(255 - 80 * value);
        const green = Math.round(255 - 120 * value);
        const blue = Math.round(255 - 180 * value);

        return {
            backgroundColor: `rgb(${red}, ${green}, ${blue})`,
        };
    };

    return (
        <Layout>
            <div className="statistics-page">
                <Typography variant="h4" gutterBottom>
                    Statistics
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Based on your 5 most recent matches
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Statistic</TableCell>
                                        <TableCell align="right">
                                            Latest Match
                                        </TableCell>
                                        <TableCell align="right">
                                            Overall
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>% of Good Shots</TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                color:
                                                    stats.latest
                                                        .goodShotsPercentage >
                                                    stats.overall
                                                        .goodShotsPercentage
                                                        ? "#F9C000"
                                                        : "#212121",
                                            }}
                                        >
                                            {stats.latest.goodShotsPercentage >
                                            stats.overall
                                                .goodShotsPercentage ? (
                                                <strong>
                                                    {stats.latest.goodShotsPercentage.toFixed(
                                                        2
                                                    )}
                                                    %
                                                </strong>
                                            ) : (
                                                stats.latest.goodShotsPercentage.toFixed(
                                                    2
                                                ) + "%"
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {stats.overall.goodShotsPercentage.toFixed(
                                                2
                                            )}
                                            %
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            % of Good Offenses
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                color:
                                                    stats.latest
                                                        .goodOffensesPercentage >
                                                    stats.overall
                                                        .goodOffensesPercentage
                                                        ? "#F9C000"
                                                        : "#212121",
                                            }}
                                        >
                                            {stats.latest
                                                .goodOffensesPercentage >
                                            stats.overall
                                                .goodOffensesPercentage ? (
                                                <strong>
                                                    {stats.latest.goodOffensesPercentage.toFixed(
                                                        2
                                                    )}
                                                    %
                                                </strong>
                                            ) : (
                                                stats.latest.goodOffensesPercentage.toFixed(
                                                    2
                                                ) + "%"
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {stats.overall.goodOffensesPercentage.toFixed(
                                                2
                                            )}
                                            %
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>% of Potted Balls</TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                color:
                                                    stats.latest
                                                        .pottedPercentage >
                                                    stats.overall
                                                        .pottedPercentage
                                                        ? "#F9C000"
                                                        : "#212121",
                                            }}
                                        >
                                            {stats.latest.pottedPercentage >
                                            stats.overall.pottedPercentage ? (
                                                <strong>
                                                    {stats.latest.pottedPercentage.toFixed(
                                                        2
                                                    )}
                                                    %
                                                </strong>
                                            ) : (
                                                stats.latest.pottedPercentage.toFixed(
                                                    2
                                                ) + "%"
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {stats.overall.pottedPercentage.toFixed(
                                                2
                                            )}
                                            %
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Avg. Time / Rack</TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                color:
                                                    stats.latest
                                                        .avgTimePerRack <
                                                    stats.overall.avgTimePerRack
                                                        ? "#F9C000"
                                                        : "#212121",
                                            }}
                                        >
                                            {stats.latest.avgTimePerRack <
                                            stats.overall.avgTimePerRack ? (
                                                <strong>
                                                    {stats.latest.avgTimePerRack.toFixed(
                                                        2
                                                    )}{" "}
                                                    mins
                                                </strong>
                                            ) : (
                                                stats.latest.avgTimePerRack.toFixed(
                                                    2
                                                ) + " mins"
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {stats.overall.avgTimePerRack.toFixed(
                                                2
                                            )}{" "}
                                            mins
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Avg. Shots / Turn</TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                color:
                                                    stats.latest
                                                        .avgShotsPerTurn >
                                                    stats.overall
                                                        .avgShotsPerTurn
                                                        ? "#F9C000"
                                                        : "#212121",
                                            }}
                                        >
                                            {stats.latest.avgShotsPerTurn >
                                            stats.overall.avgShotsPerTurn ? (
                                                <strong>
                                                    {stats.latest.avgShotsPerTurn.toFixed(
                                                        2
                                                    )}
                                                </strong>
                                            ) : (
                                                stats.latest.avgShotsPerTurn.toFixed(
                                                    2
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {stats.overall.avgShotsPerTurn.toFixed(
                                                2
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Avg. Shots / Rack</TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                color:
                                                    stats.latest
                                                        .avgShotsPerRack <
                                                    stats.overall
                                                        .avgShotsPerRack
                                                        ? "#F9C000"
                                                        : "#212121",
                                            }}
                                        >
                                            {stats.latest.avgShotsPerRack <
                                            stats.overall.avgShotsPerRack ? (
                                                <strong>
                                                    {stats.latest.avgShotsPerRack.toFixed(
                                                        2
                                                    )}
                                                </strong>
                                            ) : (
                                                stats.latest.avgShotsPerRack.toFixed(
                                                    2
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {stats.overall.avgShotsPerRack.toFixed(
                                                2
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Streak Performance
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: "50px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Streak
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                width: "50px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Class
                                        </TableCell>
                                        <TableCell>Latest Match</TableCell>
                                        <TableCell>Overall</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats.latest.streakPerformance &&
                                        Object.entries(
                                            stats.latest.streakPerformance
                                        ).map(([streakLength, data]) => {
                                            const overallFrequency =
                                                stats.overall
                                                    .streakPerformance?.[
                                                    streakLength
                                                ]?.frequency || 0;
                                            if (
                                                data.frequency === 0 &&
                                                overallFrequency === 0
                                            )
                                                return null;

                                            return (
                                                <TableRow key={streakLength}>
                                                    <TableCell
                                                        style={{
                                                            width: "50px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {streakLength}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "50px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {data.class}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "50%",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                                width: "100%",
                                                                height: "24px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    left: 0,
                                                                    top: 0,
                                                                    bottom: 0,
                                                                    width: `${
                                                                        data.frequency *
                                                                        100
                                                                    }%`,
                                                                    backgroundColor:
                                                                        getColorStyle(
                                                                            data.frequency *
                                                                                100
                                                                        )
                                                                            .backgroundColor,
                                                                    transition:
                                                                        "width 0.3s ease-in-out",
                                                                }}
                                                            ></div>
                                                            <div
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    zIndex: 1,
                                                                    textAlign:
                                                                        "left",
                                                                    lineHeight:
                                                                        "24px",
                                                                    paddingLeft:
                                                                        "8px",
                                                                    color: "#000000",
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                {data.frequency >
                                                                0
                                                                    ? `${(
                                                                          data.frequency *
                                                                          100
                                                                      ).toFixed(
                                                                          2
                                                                      )}%`
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            width: "50%",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                                width: "100%",
                                                                height: "24px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    left: 0,
                                                                    top: 0,
                                                                    bottom: 0,
                                                                    width: `${
                                                                        overallFrequency *
                                                                        100
                                                                    }%`,
                                                                    backgroundColor:
                                                                        getColorStyle(
                                                                            overallFrequency *
                                                                                100
                                                                        )
                                                                            .backgroundColor,
                                                                    transition:
                                                                        "width 0.3s ease-in-out",
                                                                }}
                                                            ></div>
                                                            <div
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    zIndex: 1,
                                                                    textAlign:
                                                                        "left",
                                                                    lineHeight:
                                                                        "24px",
                                                                    paddingLeft:
                                                                        "8px",
                                                                    color: "#000000",
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                {overallFrequency >
                                                                0
                                                                    ? `${(
                                                                          overallFrequency *
                                                                          100
                                                                      ).toFixed(
                                                                          2
                                                                      )}%`
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        </Layout>
    );
};

export default StatisticsPage;
