import React from 'react';

function DEdit2(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill} strokeLinecap="round" strokeLinejoin="round">
		<path d="M19,9H7c-2.209,0-4,1.791-4,4v28c0,2.209,1.791,4,4,4h28c2.209,0,4-1.791,4-4v-12" fill="none" stroke={fill} strokeLinecap="round" strokeMiterlimit="10" strokeWidth={strokewidth}/>
		<line fill="none" stroke={secondaryfill} strokeMiterlimit="10" strokeWidth={strokewidth} x1="33" x2="40" y1="8" y2="15"/>
		<polygon fill="none" points="23 32 13 35 16 25 38 3 45 10 23 32" stroke={secondaryfill} strokeLinecap="round" strokeMiterlimit="10" strokeWidth={strokewidth}/>
	</g>
</svg>
	);
};

export default DEdit2;