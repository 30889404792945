import React from 'react';

function DataUpload2(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill} strokeLinecap="butt" strokeLinejoin="miter">
		<line fill="none" stroke={secondaryfill} strokeMiterlimit="10" strokeWidth={strokewidth} x1="24" x2="24" y1="33" y2="4"/>
		<polyline fill="none" points="34 14 24 4 14 14" stroke={secondaryfill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth={strokewidth}/>
		<path d="M4,32v8a4,4,0,0,0,4,4H40a4,4,0,0,0,4-4V32" fill="none" stroke={fill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth={strokewidth}/>
	</g>
</svg>
	);
};

export default DataUpload2;