import React from 'react';

function EDelete(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<rect height="4" width="42" fill={fill} rx="1" ry="1" x="3" y="22"/>
	</g>
</svg>
	);
};

export default EDelete;