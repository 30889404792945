import React from 'react';

function EAdd(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<path d="M44,22H26V4c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1V22H4c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1H22v18c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1V26h18c.552,0,1-.448,1-1v-2c0-.552-.448-1-1-1Z" fill={fill}/>
	</g>
</svg>
	);
};

export default EAdd;