import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import StatisticsPage from "./pages/statistics";
import MatchesPage from "./pages/matches";
import MatchDetailPage from "./pages/matchDetails";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/statistics" element={<StatisticsPage />} />
                <Route path="/matches" element={<MatchesPage />} />
                <Route path="/match/:id" element={<MatchDetailPage />} />

                <Route path="*" element={<Navigate to="/statistics" />} />
            </Routes>
        </Router>
    );
};

export default App;
 