/*
    This page will be used to display the matche's detail that the user has saved.
    Matches stored in the Firestore database, collection name: "Matches"
    This match's detail will be the content of document in the collection, with ID is the match's ID.

    The page will load data of the match from Firestore and display it in the table.

    The game will have multiple racks, and in each rack, I have multiple turns to join the table. 
    I want the data of the game will be store as an array of objects. 
    Each object is a rack's result, I think it might be like: {turnString: 5321, offenses: total of offenses, badOffenses: number of bad offenses, defenses: total defenses, bad defenses...}. 
    turnString is a string of digits, each digit represent the number of shots I made in that turn, this number equals total of shots I input to the fields in each turn. 
    Above the turn navigate, I want 10 buttons from 1 to 10, with rounded border. 
    it have 2 stages are selected or not, if selected, the background of button change. This represent which ball I potted in that rack.

*/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Chip,
    Card,
    CardContent,
    Box,
} from "@mui/material";

import Layout from "../theme/layout";
import Icon from "../theme/vectors/bundle";
import SlidePanel from "../theme/partials/slidepanel";
import { NumField } from "../theme/inputs/numfields";
import {
    StyledCard,
    StyledCardHeadline,
    StyledCardContent,
} from "../theme/partials/card";

const MatchDetailsPage = () => {
    const { id } = useParams();
    const [match, setMatch] = useState(null);
    const [matchDuration, setMatchDuration] = useState(null);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isStatisticPanelOpen, setIsStatisticPanelOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const [longestWinStreak, setLongestWinStreak] = useState(0);
    const [longestLoseStreak, setLongestLoseStreak] = useState(0);
    const [totalShots, setTotalShots] = useState(0);
    const [totalGoodShots, setTotalGoodShots] = useState(0);
    const [percentages, setPercentages] = useState({
        goodShots: 0,
        goodOffense: 0,
        goodDefense: 0,
        goodCounter: 0,
        goodBreak: 0,
    });
    const [averageShotsPerRack, setAverageShotsPerRack] = useState(0);
    const [totalBallsPotted, setTotalBallsPotted] = useState(0);
    const [percentBallsPotted, setPercentBallsPotted] = useState(0);
    const [ballPottingStats, setBallPottingStats] = useState([]);

    const [rackStartTime, setRackStartTime] = useState(null);
    const [rackNumber, setRackNumber] = useState(1);
    const [pottedBalls, setPottedBalls] = useState([]);
    const [selectedTurn, setSelectedTurn] = useState(0);
    const [turns, setTurns] = useState([
        {
            goodOffense: 0,
            badOffense: 0,
            goodDefense: 0,
            badDefense: 0,
            goodCounter: 0,
            badCounter: 0,
            goodBreak: 0,
            badBreak: 0,
            pottedBalls: [],
        },
    ]);

    const [isEditingExistingRack, setIsEditingExistingRack] = useState(false);
    const [rackResult, setRackResult] = useState(null);

    const fetchMatchDetails = async () => {
        try {
            const matchDoc = doc(db, "Matches", id);
            const matchSnapshot = await getDoc(matchDoc);
            if (matchSnapshot.exists()) {
                const matchData = {
                    id: matchSnapshot.id,
                    ...matchSnapshot.data(),
                };
                setMatch(matchData);
                if (matchData.racks) {
                    calculateMetrics(matchData.racks, matchData.matchType);
                }
                // Calculate and set match duration
                if (matchData.matchDuration) {
                    setMatchDuration(matchData.matchDuration);
                }
            } else {
                console.log("No such match!");
            }
        } catch (error) {
            console.error("Error fetching match details: ", error);
        }
    };

    const getStreakClass = (streakLength) => {
        if (streakLength === 1) return "I-";
        if (streakLength === 2) return "I";
        if (streakLength === 3) return "I+";
        if (streakLength === 4) return "H-";
        if (streakLength === 5) return "H";
        if (streakLength === 6) return "H+";
        if (streakLength === 7) return "G-";
        if (streakLength === 8) return "G";
        if (streakLength === 9) return "G+";
        if (streakLength >= 10) return "F-";
    };

    const getBallCount = (matchType) => {
        switch (matchType) {
            case "9 balls":
                return 9;
            case "10 balls":
                return 10;
            // Add more cases here for future match types
            default:
                return 9; // Default to 9 balls if matchType is not recognized
        }
    };

    const addNewRack = () => {
        setRackNumber(match.racks ? match.racks.length + 1 : 1);
        setPottedBalls([]);
        setTurns([
            {
                goodOffense: 0,
                badOffense: 0,
                goodDefense: 0,
                badDefense: 0,
                goodCounter: 0,
                badCounter: 0,
                goodBreak: 0,
                badBreak: 0,
                pottedBalls: [],
            },
        ]);
        setSelectedTurn(0);
        setIsEditingExistingRack(false);
        setRackResult(null);
        setRackStartTime(Date.now());
        setIsPanelOpen(true);
    };

    const handleEndRack = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleRackResult = async (result) => {
        setIsDialogOpen(false);
        setRackResult(result);

        // Clean up the turns array
        const cleanedTurns = turns.filter((turn) => {
            const totalShots =
                turn.goodOffense +
                turn.badOffense +
                turn.goodDefense +
                turn.badDefense +
                turn.goodCounter +
                turn.badCounter +
                turn.goodBreak +
                turn.badBreak;
            return totalShots > 0;
        });

        // Update the state with cleaned turns
        setTurns(cleanedTurns);

        const rackEndTime = Date.now();
        const averageShotsPerTurn = calculateAverageShotsPerTurn(cleanedTurns);
        const longestPottedStreak = calculateLongestPottedStreak(cleanedTurns);

        const rackData = {
            rackNumber,
            pottedBalls: cleanedTurns.flatMap((turn) => turn.pottedBalls || []),
            turnString: cleanedTurns
                .map(
                    (turn) =>
                        turn.goodOffense +
                        turn.badOffense +
                        turn.goodDefense +
                        turn.badDefense +
                        turn.goodCounter +
                        turn.badCounter +
                        turn.goodBreak +
                        turn.badBreak
                )
                .join(""),
            turns: cleanedTurns,
            averageShotsPerTurn,
            longestPottedStreak,
            rackResult: result, // Win or Lose
            startTime: rackStartTime,
            endTime: rackEndTime,
            duration: rackEndTime - rackStartTime,
        };

        try {
            const matchRef = doc(db, "Matches", id);
            if (isEditingExistingRack) {
                await updateDoc(matchRef, {
                    [`racks.${rackNumber - 1}`]: rackData,
                });
            } else {
                await updateDoc(matchRef, {
                    racks: arrayUnion(rackData),
                });
            }

            // Calculate and update match averages
            const updatedMatch = await getDoc(matchRef);
            const matchData = updatedMatch.data();
            const allRacks = isEditingExistingRack
                ? [
                      ...matchData.racks.slice(0, rackNumber - 1),
                      rackData,
                      ...matchData.racks.slice(rackNumber),
                  ]
                : [...matchData.racks, rackData];

            const totalAverageShotsPerTurn =
                allRacks.reduce(
                    (sum, rack) => sum + rack.averageShotsPerTurn,
                    0
                ) / allRacks.length;
            const maxLongestStreak = Math.max(
                ...allRacks.map((rack) => rack.longestPottedStreak)
            );

            // Calculate match duration
            const matchStartTime = Math.min(
                ...allRacks.map((rack) => rack.startTime)
            );
            const matchEndTime = Math.max(
                ...allRacks.map((rack) => rack.endTime)
            );
            const matchDuration = matchEndTime - matchStartTime;

            await updateDoc(matchRef, {
                averageShotsPerTurn: totalAverageShotsPerTurn,
                longestStreak: maxLongestStreak,
                matchDuration: matchDuration,
            });

            console.log("Rack saved successfully");
            fetchMatchDetails(); // Refresh the match data
            setIsPanelOpen(false);
        } catch (error) {
            console.error("Error saving rack: ", error);
        }
    };

    const openRackDetails = (rack) => {
        setRackNumber(rack.rackNumber);
        setPottedBalls(rack.pottedBalls);
        setTurns(rack.turns);
        setSelectedTurn(0);
        setIsEditingExistingRack(true);
        setIsPanelOpen(true);
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteRack = async () => {
        try {
            const matchRef = doc(db, "Matches", id);
            const matchDoc = await getDoc(matchRef);
            const matchData = matchDoc.data();

            // Remove the rack from the racks array
            const updatedRacks = matchData.racks.filter(
                (rack) => rack.rackNumber !== rackNumber
            );

            // Update the rack numbers for the remaining racks
            const reorderedRacks = updatedRacks.map((rack, index) => ({
                ...rack,
                rackNumber: index + 1,
            }));

            // Recalculate averageShotsPerTurn
            const totalAverageShotsPerTurn =
                reorderedRacks.length > 0
                    ? reorderedRacks.reduce(
                          (sum, rack) => sum + rack.averageShotsPerTurn,
                          0
                      ) / reorderedRacks.length
                    : 0;

            // Recalculate longestStreak
            const maxLongestStreak =
                reorderedRacks.length > 0
                    ? Math.max(
                          ...reorderedRacks.map(
                              (rack) => rack.longestPottedStreak
                          )
                      )
                    : 0;

            // Recalculate matchDuration
            const matchStartTime =
                reorderedRacks.length > 0
                    ? Math.min(...reorderedRacks.map((rack) => rack.startTime))
                    : 0;
            const matchEndTime =
                reorderedRacks.length > 0
                    ? Math.max(...reorderedRacks.map((rack) => rack.endTime))
                    : 0;
            const matchDuration = matchEndTime - matchStartTime;

            // Update the match document
            await updateDoc(matchRef, {
                racks: reorderedRacks,
                averageShotsPerTurn: totalAverageShotsPerTurn,
                longestStreak: maxLongestStreak,
                matchDuration: matchDuration,
            });

            console.log("Rack deleted successfully");
            setIsDeleteDialogOpen(false);
            setIsPanelOpen(false);
            fetchMatchDetails(); // Refresh the match data
        } catch (error) {
            console.error("Error deleting rack: ", error);
        }
    };

    const addNewTurn = () => {
        const newTurn = {
            goodOffense: 0,
            badOffense: 0,
            goodDefense: 0,
            badDefense: 0,
            goodCounter: 0,
            badCounter: 0,
            goodBreak: 0,
            badBreak: 0,
        };
        setTurns((prev) => [...prev, newTurn]);
        setSelectedTurn(turns.length);
    };

    const calculateTurnSummary = (turn) => {
        if (!turn) return { details: [], pottedBalls: [] };

        const details = [
            { label: "Good Break", value: turn.goodBreak || 0 },
            { label: "Bad Break", value: turn.badBreak || 0 },
            { label: "Good Offenses", value: turn.goodOffense || 0 },
            { label: "Bad Offenses", value: turn.badOffense || 0 },
            { label: "Good Defenses", value: turn.goodDefense || 0 },
            { label: "Bad Defenses", value: turn.badDefense || 0 },
            { label: "Good Counters", value: turn.goodCounter || 0 },
            { label: "Bad Counters", value: turn.badCounter || 0 },
        ].filter((item) => item.value > 0);

        const pottedBalls = turn.pottedBalls || [];

        return { details, pottedBalls };
    };

    const updateTurnData = (turnIndex, field, value) => {
        setTurns((prev) => {
            const newTurns = [...prev];
            newTurns[turnIndex] = { ...newTurns[turnIndex], [field]: value };
            return newTurns;
        });
    };

    const togglePottedBall = (ball) => {
        setTurns((prev) => {
            const newTurns = [...prev];
            const currentTurn = newTurns[selectedTurn];
            if (!currentTurn.pottedBalls) {
                currentTurn.pottedBalls = [];
            }
            if (currentTurn.pottedBalls.includes(ball)) {
                currentTurn.pottedBalls = currentTurn.pottedBalls.filter(
                    (b) => b !== ball
                );
            } else {
                currentTurn.pottedBalls = [...currentTurn.pottedBalls, ball];
            }
            return newTurns;
        });

        setPottedBalls((prevBalls) => {
            if (prevBalls.includes(ball)) {
                return prevBalls.filter((b) => b !== ball);
            } else {
                return [...prevBalls, ball];
            }
        });
    };

    const calculateAverageShotsPerTurn = (turns) => {
        const totalShots = turns.reduce(
            (sum, turn) =>
                sum +
                turn.goodOffense +
                turn.badOffense +
                turn.goodDefense +
                turn.badDefense +
                turn.goodCounter +
                turn.badCounter +
                turn.goodBreak +
                turn.badBreak,
            0
        );
        return turns.length > 0 ? totalShots / turns.length : 0;
    };

    const calculateLongestPottedStreak = (turns) => {
        let longestStreak = 0;

        turns.forEach((turn) => {
            if (
                Array.isArray(turn.pottedBalls) &&
                turn.pottedBalls.length > 0
            ) {
                // Sort the potted balls for each turn
                const sortedPottedBalls = [...turn.pottedBalls].sort(
                    (a, b) => a - b
                );

                let turnStreak = 1;
                for (let i = 1; i < sortedPottedBalls.length; i++) {
                    if (sortedPottedBalls[i] === sortedPottedBalls[i - 1] + 1) {
                        turnStreak++;
                    } else {
                        longestStreak = Math.max(longestStreak, turnStreak);
                        turnStreak = 1;
                    }
                }
                longestStreak = Math.max(longestStreak, turnStreak);
            }
        });

        return longestStreak;
    };

    const calculateMetrics = (racks, matchType) => {
        let winStreak = 0;
        let loseStreak = 0;
        let maxWinStreak = 0;
        let maxLoseStreak = 0;
        let totalShots = 0;
        let totalGoodShots = 0;
        let totalOffense = 0;
        let totalDefense = 0;
        let totalCounter = 0;
        let totalBreak = 0;
        let goodOffense = 0;
        let goodDefense = 0;
        let goodCounter = 0;
        let goodBreak = 0;
        let totalBallsPotted = 0;
        let totalPossibleBalls = 0;
        let ballStats = {};

        racks.forEach((rack) => {
            // Calculate streaks
            if (rack.rackResult === "Win") {
                loseStreak = 0;
                winStreak++;
                maxWinStreak = Math.max(maxWinStreak, winStreak);
            } else {
                winStreak = 0;
                loseStreak++;
                maxLoseStreak = Math.max(maxLoseStreak, loseStreak);
            }

            totalBallsPotted += rack.pottedBalls.length;
            totalPossibleBalls += getBallCount(matchType);

            rack.pottedBalls.forEach((ball) => {
                if (!ballStats[ball]) ballStats[ball] = { potted: 0, total: 0 };
                ballStats[ball].potted++;
            });

            for (let i = 1; i <= getBallCount(matchType); i++) {
                if (!ballStats[i]) ballStats[i] = { potted: 0, total: 0 };
                ballStats[i].total++;
            }

            // Calculate shot totals
            rack.turns.forEach((turn) => {
                totalShots +=
                    turn.goodOffense +
                    turn.badOffense +
                    turn.goodDefense +
                    turn.badDefense +
                    turn.goodCounter +
                    turn.badCounter +
                    turn.goodBreak +
                    turn.badBreak;
                totalGoodShots +=
                    turn.goodOffense +
                    turn.goodDefense +
                    turn.goodCounter +
                    turn.goodBreak;

                totalOffense += turn.goodOffense + turn.badOffense;
                totalDefense += turn.goodDefense + turn.badDefense;
                totalCounter += turn.goodCounter + turn.badCounter;
                totalBreak += turn.goodBreak + turn.badBreak;

                goodOffense += turn.goodOffense;
                goodDefense += turn.goodDefense;
                goodCounter += turn.goodCounter;
                goodBreak += turn.goodBreak;
            });
        });

        const averageShotsPerRack =
            racks.length > 0 ? totalShots / racks.length : 0;
        const percentBallsPotted =
            totalPossibleBalls > 0
                ? (totalBallsPotted / totalPossibleBalls) * 100
                : 0;

        const ballPottingStats = Object.entries(ballStats)
            .map(([ball, stats]) => ({
                ball: parseInt(ball),
                percentage: (stats.potted / stats.total) * 100,
            }))
            .sort((a, b) => b.percentage - a.percentage);

        setAverageShotsPerRack(averageShotsPerRack);
        setTotalBallsPotted(totalBallsPotted);
        setPercentBallsPotted(percentBallsPotted);
        setBallPottingStats(ballPottingStats);

        setLongestWinStreak(maxWinStreak);
        setLongestLoseStreak(maxLoseStreak);
        setTotalShots(totalShots);
        setTotalGoodShots(totalGoodShots);
        setPercentages({
            goodShots: totalShots > 0 ? (totalGoodShots / totalShots) * 100 : 0,
            goodOffense:
                totalOffense > 0 ? (goodOffense / totalOffense) * 100 : 0,
            goodDefense:
                totalDefense > 0 ? (goodDefense / totalDefense) * 100 : 0,
            goodCounter:
                totalCounter > 0 ? (goodCounter / totalCounter) * 100 : 0,
            goodBreak: totalBreak > 0 ? (goodBreak / totalBreak) * 100 : 0,
        });
    };

    const calculateMatchScore = (racks) => {
        if (!racks || racks.length === 0) return { wins: 0, losses: 0 };
        const wins = racks.filter((rack) => rack.rackResult === "Win").length;
        const losses = racks.filter(
            (rack) => rack.rackResult === "Lose"
        ).length;
        return { wins, losses };
    };

    const calculateAverageRackDuration = (racks) => {
        if (!racks || racks.length === 0) return "N/A";

        const totalDuration = racks.reduce((sum, rack) => {
            if (rack.startTime && rack.endTime) {
                return sum + (rack.endTime - rack.startTime);
            }
            return sum;
        }, 0);

        const avgDurationMs = totalDuration / racks.length;
        const avgDurationMinutes = Math.round(avgDurationMs / 60000);

        return `${avgDurationMinutes} min`;
    };

    const formatDuration = (durationMs) => {
        const seconds = Math.floor(durationMs / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        const remainingMinutes = minutes % 60;
        const remainingSeconds = seconds % 60;

        return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
    };

    const calculateStreakPerformance = (turns) => {
        let streaks = {};
        let currentStreak = 0;
        let totalStreaks = 0;
        const maxBalls = getBallCount(match.matchType);

        turns.forEach((turn) => {
            const pottedBalls = turn.pottedBalls || [];

            if (pottedBalls.length > 0) {
                currentStreak = pottedBalls.length;
                streaks[currentStreak] = (streaks[currentStreak] || 0) + 1;
                totalStreaks++;
            } else if (currentStreak > 0) {
                currentStreak = 0;
            }
        });

        let streakFrequency = {};
        for (let i = 1; i <= maxBalls; i++) {
            streakFrequency[i] = {
                frequency: (streaks[i] || 0) / totalStreaks || 0,
                class: getStreakClass(i),
            };
        }

        return streakFrequency;
    };

    const getColorStyle = (percentage) => {
        // Convert percentage to a value between 0 and 1
        const value = percentage / 100;

        // Calculate the RGB components
        const red = Math.round(255 - 80 * value);
        const green = Math.round(255 - 120 * value);
        const blue = Math.round(255 - 180 * value);

        return {
            backgroundColor: `rgb(${red}, ${green}, ${blue})`,
        };
    };

    const calculateEasyWins = (racks) => {
        return racks.reduce((easyWins, rack) => {
            if (rack.rackResult === "Win" && rack.turns.length > 0) {
                const lastTurn = rack.turns[rack.turns.length - 1];
                if (
                    lastTurn.goodOffense === 1 &&
                    lastTurn.badOffense === 0 &&
                    lastTurn.goodDefense === 0 &&
                    lastTurn.badDefense === 0 &&
                    lastTurn.goodCounter === 0 &&
                    lastTurn.badCounter === 0
                ) {
                    return easyWins + 1;
                }
            }
            return easyWins;
        }, 0);
    };

    useEffect(() => {
        fetchMatchDetails();
    }, [id]);

    if (!match) {
        return <Layout>Loading...</Layout>;
    }

    return (
        <Layout>
            <div className="match-details-page">
                <h1>Match Details: vs {match.opponentName}</h1>
                <Box mb={2}>
                    <Chip
                        label={new Date(match.matchDate).toLocaleDateString(
                            "en-GB",
                            {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                            }
                        )}
                        size="small"
                    />
                    &nbsp;
                    {matchDuration && (
                        <>
                            <Chip
                                label={formatDuration(matchDuration)}
                                size="small"
                            />
                            &nbsp;
                        </>
                    )}
                    &nbsp;
                    <Chip label={match.matchType} size="small" />
                    &nbsp;
                    {match.racks && match.racks.length > 0 ? (
                        <Chip
                            label={(() => {
                                const score = calculateMatchScore(match.racks);
                                if (score.wins > score.losses) return "W";
                                if (score.wins < score.losses) return "L";
                                return "D";
                            })()}
                            color={(() => {
                                const score = calculateMatchScore(match.racks);
                                if (score.wins > score.losses) return "success";
                                if (score.wins < score.losses) return "error";
                                return "default";
                            })()}
                            size="small"
                        />
                    ) : null}
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6} lg={2}>
                        <StyledCard elevation={6}>
                            <CardContent>
                                <StyledCardHeadline variant="body1">
                                    Match Score
                                </StyledCardHeadline>
                                <StyledCardContent variant="h4">
                                    <strong>
                                        {match.racks ? (
                                            <>
                                                {
                                                    calculateMatchScore(
                                                        match.racks
                                                    ).wins
                                                }{" "}
                                                -{" "}
                                                {
                                                    calculateMatchScore(
                                                        match.racks
                                                    ).losses
                                                }
                                            </>
                                        ) : (
                                            "0 - 0"
                                        )}
                                    </strong>
                                </StyledCardContent>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    <Grid item xs={6} md={6} lg={2}>
                        <StyledCard elevation={6}>
                            <CardContent>
                                <StyledCardHeadline variant="body1">
                                    Avg.Shots / Turn
                                </StyledCardHeadline>
                                <StyledCardContent variant="h4">
                                    <strong>
                                        {match.averageShotsPerTurn
                                            ? match.averageShotsPerTurn.toFixed(
                                                  2
                                              )
                                            : "N/A"}
                                    </strong>
                                </StyledCardContent>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    <Grid item xs={6} md={6} lg={2}>
                        <StyledCard elevation={6}>
                            <CardContent>
                                <StyledCardHeadline variant="body1">
                                    % of Good Shots
                                </StyledCardHeadline>
                                <StyledCardContent variant="h4">
                                    <strong>
                                        {percentages.goodShots.toFixed(1)}%
                                    </strong>
                                </StyledCardContent>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    <Grid item xs={6} md={6} lg={2}>
                        <StyledCard elevation={6}>
                            <CardContent>
                                <StyledCardHeadline variant="body1">
                                    % of Balls Potted
                                </StyledCardHeadline>
                                <StyledCardContent variant="h4">
                                    <strong>
                                        {percentBallsPotted.toFixed(1)}%
                                    </strong>
                                </StyledCardContent>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon name="addBold" />}
                    onClick={addNewRack}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                    New Rack
                </Button>
                &nbsp;
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Icon name="chartBar" />}
                    onClick={() => setIsStatisticPanelOpen(true)}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                    Statistics
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rack #</TableCell>
                                <TableCell>Result</TableCell>
                                <TableCell>Potted Balls</TableCell>
                                <TableCell>Turn String</TableCell>
                                <TableCell>Avg Shots/Turn</TableCell>
                                <TableCell>Longest Streak</TableCell>
                                <TableCell>Good Offenses</TableCell>
                                <TableCell>Good Defenses</TableCell>
                                <TableCell>Good Counters</TableCell>
                                <TableCell>Good Breaks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {match.racks &&
                                [...match.racks]
                                    .reverse()
                                    .map((rack, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() =>
                                                openRackDetails(rack)
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell>
                                                {rack.rackNumber}
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={rack.rackResult}
                                                    color={
                                                        rack.rackResult ===
                                                        "Win"
                                                            ? "success"
                                                            : "error"
                                                    }
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {rack.pottedBalls.join(", ")}
                                            </TableCell>
                                            <TableCell>
                                                {rack.turnString}
                                            </TableCell>
                                            <TableCell>
                                                {rack.averageShotsPerTurn.toFixed(
                                                    2
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {rack.longestPottedStreak}
                                            </TableCell>
                                            <TableCell>
                                                {(() => {
                                                    const goodOffenses =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodOffense,
                                                            0
                                                        );
                                                    const totalOffenses =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodOffense +
                                                                turn.badOffense,
                                                            0
                                                        );
                                                    // const percentage = totalOffenses > 0 ? (goodOffenses / totalOffenses) * 100 : 0;
                                                    // return `${goodOffenses} / ${totalOffenses} - ( ${percentage.toFixed(1)}% )`;
                                                    return `${goodOffenses} / ${totalOffenses}`;
                                                })()}
                                            </TableCell>
                                            <TableCell>
                                                {(() => {
                                                    const goodDefenses =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodDefense,
                                                            0
                                                        );
                                                    const totalDefenses =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodDefense +
                                                                turn.badDefense,
                                                            0
                                                        );
                                                    // const percentage = totalDefenses > 0 ? (goodDefenses / totalDefenses) * 100 : 0;
                                                    // return `${goodDefenses} / ${totalDefenses} - ( ${percentage.toFixed(1)}% )`;
                                                    return `${goodDefenses} / ${totalDefenses}`;
                                                })()}
                                            </TableCell>
                                            <TableCell>
                                                {(() => {
                                                    const goodCounters =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodCounter,
                                                            0
                                                        );
                                                    const totalCounters =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodCounter +
                                                                turn.badCounter,
                                                            0
                                                        );
                                                    // const percentage = totalCounters > 0 ? (goodCounters / totalCounters) * 100 : 0;
                                                    // return `${goodCounters} / ${totalCounters} - ( ${percentage.toFixed(1)}% )`;
                                                    return `${goodCounters} / ${totalCounters}`;
                                                })()}
                                            </TableCell>
                                            <TableCell>
                                                {(() => {
                                                    const goodBreaks =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodBreak,
                                                            0
                                                        );
                                                    const totalBreaks =
                                                        rack.turns.reduce(
                                                            (sum, turn) =>
                                                                sum +
                                                                turn.goodBreak +
                                                                turn.badBreak,
                                                            0
                                                        );
                                                    // const percentage = totalBreaks > 0 ? (goodBreaks / totalBreaks) * 100 : 0;
                                                    // return `${goodBreaks} / ${totalBreaks} - ( ${percentage.toFixed(1)}% )`;
                                                    return `${goodBreaks} / ${totalBreaks}`;
                                                })()}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SlidePanel
                    title={
                        isEditingExistingRack
                            ? `Edit Rack #${rackNumber}`
                            : `Add New Rack #${rackNumber}`
                    }
                    isOpen={isPanelOpen}
                    onClose={() => setIsPanelOpen(false)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    gap: "10px",
                                    marginBottom: "20px",
                                }}
                            >
                                {[...Array(getBallCount(match.matchType))].map(
                                    (_, index) => {
                                        const ball = index + 1;
                                        return (
                                            <Button
                                                key={ball}
                                                variant="outlined"
                                                style={{
                                                    borderRadius: "50%",
                                                    minWidth: "40px",
                                                    height: "40px",
                                                    padding: 0,
                                                    backgroundColor:
                                                        pottedBalls.includes(
                                                            ball
                                                        )
                                                            ? "#4caf50"
                                                            : "transparent",
                                                    color: pottedBalls.includes(
                                                        ball
                                                    )
                                                        ? "white"
                                                        : "inherit",
                                                }}
                                                onClick={() =>
                                                    togglePottedBall(ball)
                                                }
                                            >
                                                {ball}
                                            </Button>
                                        );
                                    }
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                    marginBottom: "20px",
                                    alignItems: "center",
                                }}
                            >
                                {turns.map((turn, index) => (
                                    <Button
                                        key={index}
                                        variant="outlined"
                                        onClick={() => setSelectedTurn(index)}
                                        style={{
                                            backgroundColor:
                                                selectedTurn === index
                                                    ? "#4caf50"
                                                    : "transparent",
                                            color:
                                                selectedTurn === index
                                                    ? "white"
                                                    : "inherit",
                                        }}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        </Grid>

                        {selectedTurn !== null && (
                            <React.Fragment key={selectedTurn}>
                                <Grid item xs={12}>
                                    <div>
                                        {calculateTurnSummary(
                                            turns[selectedTurn]
                                        ).details.map((detail, index) => (
                                            <Typography
                                                key={index}
                                                variant="body2"
                                            >
                                                {`${detail.label}: ${detail.value}`}
                                            </Typography>
                                        ))}
                                        <Typography variant="body2">
                                            {`Balls potted: ${
                                                calculateTurnSummary(
                                                    turns[selectedTurn]
                                                ).pottedBalls.join(", ") ||
                                                "None"
                                            }`}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Good Offenses:"
                                        value={
                                            turns[selectedTurn]?.goodOffense ||
                                            0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "goodOffense",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={10}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Bad Offenses:"
                                        value={
                                            turns[selectedTurn]?.badOffense || 0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "badOffense",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={10}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Good Defenses:"
                                        value={
                                            turns[selectedTurn]?.goodDefense ||
                                            0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "goodDefense",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={10}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Bad Defenses:"
                                        value={
                                            turns[selectedTurn]?.badDefense || 0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "badDefense",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={10}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Good Counters:"
                                        value={
                                            turns[selectedTurn]?.goodCounter ||
                                            0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "goodCounter",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={10}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Bad Counters:"
                                        value={
                                            turns[selectedTurn]?.badCounter || 0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "badCounter",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={10}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Good Breaks:"
                                        value={
                                            turns[selectedTurn]?.goodBreak || 0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "goodBreak",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={1}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={6} lg={3}>
                                    <NumField
                                        label="Bad Breaks:"
                                        value={
                                            turns[selectedTurn]?.badBreak || 0
                                        }
                                        onValueChange={(value) =>
                                            updateTurnData(
                                                selectedTurn,
                                                "badBreak",
                                                value
                                            )
                                        }
                                        min={0}
                                        max={1}
                                        step={1}
                                        decimalPlaces={0}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                            </React.Fragment>
                        )}

                        <Grid item xs={12}>
                            {!isEditingExistingRack && (
                                <>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={addNewTurn}
                                        disabled={Object.values(
                                            turns[selectedTurn] || {}
                                        ).every((value) => value === 0)}
                                        style={{ marginRight: "1em" }}
                                    >
                                        Add Turn
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleEndRack}
                                        style={{ marginRight: "1em" }}
                                    >
                                        End Rack
                                    </Button>
                                </>
                            )}
                            {isEditingExistingRack && (
                                <>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() =>
                                            setIsDeleteDialogOpen(true)
                                        }
                                        style={{ marginRight: "1em" }}
                                    >
                                        Delete Rack
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </SlidePanel>
                <SlidePanel
                    title="Statistics"
                    isOpen={isStatisticPanelOpen}
                    onClose={() => setIsStatisticPanelOpen(false)}
                >
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                1. Avg. Performance:
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Avg. Shots / Turn :
                                            </TableCell>
                                            <TableCell>
                                                {match.averageShotsPerTurn
                                                    ? match.averageShotsPerTurn.toFixed(
                                                          2
                                                      )
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                % of Good Shots :
                                            </TableCell>
                                            <TableCell>
                                                {percentages.goodShots.toFixed(
                                                    1
                                                )}
                                                %
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Avg. Rack's Duration :
                                            </TableCell>
                                            <TableCell>
                                                {calculateAverageRackDuration(
                                                    match.racks
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Avg. Shots / Rack :
                                            </TableCell>
                                            <TableCell>
                                                {averageShotsPerRack.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                2. Potted Performance:
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Balls Potted :
                                            </TableCell>
                                            <TableCell>
                                                {totalBallsPotted}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                % of Balls Potted :
                                            </TableCell>
                                            <TableCell>
                                                {percentBallsPotted.toFixed(1)}%
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Longest Potted Streak :
                                            </TableCell>
                                            <TableCell>
                                                {match.longestStreak
                                                    ? match.longestStreak
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Worst Potted Balls :
                                            </TableCell>
                                            <TableCell>
                                                {ballPottingStats
                                                    .slice(-4)
                                                    .reverse()
                                                    .map(
                                                        (stat, index) =>
                                                            `${stat.ball}`
                                                    )
                                                    .join(", ")}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                3. Intent's Quality:
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Good Offense :
                                            </TableCell>
                                            <TableCell>
                                                {percentages.goodOffense.toFixed(
                                                    1
                                                )}
                                                %
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Good Defense :
                                            </TableCell>
                                            <TableCell>
                                                {percentages.goodDefense.toFixed(
                                                    1
                                                )}
                                                %
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Good Counters :
                                            </TableCell>
                                            <TableCell>
                                                {percentages.goodCounter.toFixed(
                                                    1
                                                )}
                                                %
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Good Breaks :</TableCell>
                                            <TableCell>
                                                {percentages.goodBreak.toFixed(
                                                    1
                                                )}
                                                %
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                4. Match's Result:
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Match Score :</TableCell>
                                            <TableCell>
                                                {match.racks ? (
                                                    <>
                                                        {
                                                            calculateMatchScore(
                                                                match.racks
                                                            ).wins
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            calculateMatchScore(
                                                                match.racks
                                                            ).losses
                                                        }
                                                        &nbsp;
                                                        <Chip
                                                            label={(() => {
                                                                const score =
                                                                    calculateMatchScore(
                                                                        match.racks
                                                                    );
                                                                if (
                                                                    score.wins >
                                                                    score.losses
                                                                )
                                                                    return "W";
                                                                if (
                                                                    score.wins <
                                                                    score.losses
                                                                )
                                                                    return "L";
                                                                return "D";
                                                            })()}
                                                            color={(() => {
                                                                const score =
                                                                    calculateMatchScore(
                                                                        match.racks
                                                                    );
                                                                if (
                                                                    score.wins >
                                                                    score.losses
                                                                )
                                                                    return "success";
                                                                if (
                                                                    score.wins <
                                                                    score.losses
                                                                )
                                                                    return "error";
                                                                return "default";
                                                            })()}
                                                            size="small"
                                                        />
                                                    </>
                                                ) : (
                                                    "0 - 0"
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Longest Win Streak :
                                            </TableCell>
                                            <TableCell>
                                                {longestWinStreak}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Longest Lose Streak :
                                            </TableCell>
                                            <TableCell>
                                                {longestLoseStreak}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Easy Win :</TableCell>
                                            <TableCell>
                                                {match.racks
                                                    ? calculateEasyWins(
                                                          match.racks
                                                      )
                                                    : 0}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="p" gutterBottom>
                                <strong>Streak Performance</strong>
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    width: "50px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Streak
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    width: "50px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Class
                                            </TableCell>
                                            <TableCell>
                                                Streak Performance
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {match.racks
                                            ? Object.entries(
                                                  calculateStreakPerformance(
                                                      match.racks.flatMap(
                                                          (rack) => rack.turns
                                                      )
                                                  )
                                              ).map(([streakLength, data]) => {
                                                  if (data.frequency === 0)
                                                      return null;
                                                  return (
                                                      <TableRow
                                                          key={streakLength}
                                                      >
                                                          <TableCell
                                                              style={{
                                                                  width: "50px",
                                                                  textAlign:
                                                                      "center",
                                                              }}
                                                          >
                                                              {streakLength}
                                                          </TableCell>
                                                          <TableCell
                                                              style={{
                                                                  width: "50px",
                                                                  textAlign:
                                                                      "center",
                                                              }}
                                                          >
                                                              {data.class}
                                                          </TableCell>
                                                          <TableCell
                                                              style={{
                                                                  padding: 0,
                                                              }}
                                                          >
                                                              <div
                                                                  style={{
                                                                      position:
                                                                          "relative",
                                                                      width: "100%",
                                                                      height: "24px",
                                                                  }}
                                                              >
                                                                  <div
                                                                      style={{
                                                                          position:
                                                                              "absolute",
                                                                          left: 0,
                                                                          top: 0,
                                                                          bottom: 0,
                                                                          width: `${
                                                                              data.frequency *
                                                                              100
                                                                          }%`,
                                                                          backgroundColor:
                                                                              getColorStyle(
                                                                                  data.frequency *
                                                                                      100
                                                                              )
                                                                                  .backgroundColor,
                                                                          transition:
                                                                              "width 0.3s ease-in-out",
                                                                      }}
                                                                  ></div>
                                                                  <div
                                                                      style={{
                                                                          position:
                                                                              "relative",
                                                                          zIndex: 1,
                                                                          textAlign:
                                                                              "left",
                                                                          lineHeight:
                                                                              "24px",
                                                                          paddingLeft:
                                                                              "8px",
                                                                          color: "#000000",
                                                                          fontWeight:
                                                                              "bold",
                                                                      }}
                                                                  >
                                                                      {data.frequency >
                                                                      0
                                                                          ? `${(
                                                                                data.frequency *
                                                                                100
                                                                            ).toFixed(
                                                                                2
                                                                            )}%`
                                                                          : ""}
                                                                  </div>
                                                              </div>
                                                          </TableCell>
                                                      </TableRow>
                                                  );
                                              })
                                            : ""}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </SlidePanel>
                <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>End Rack</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Did you win this rack?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDialogClose}
                            color="primary"
                            autoFocus
                        >
                            Cancel
                        </Button>{" "}
                        &nbsp;
                        <Button
                            onClick={() => handleRackResult("Lose")}
                            color="secondary"
                        >
                            Lose
                        </Button>{" "}
                        &nbsp;
                        <Button
                            onClick={() => handleRackResult("Win")}
                            color="primary"
                        >
                            Win
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDeleteDialogOpen}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete Rack?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this rack? This
                            action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDeleteDialogOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDeleteRack}
                            color="error"
                            autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    );
};

export default MatchDetailsPage;
