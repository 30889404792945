import React, { useCallback } from 'react';
import { Box, IconButton, Typography, styled } from "@mui/material";
import Icon from "../vectors/bundle";

const OverlayPanel = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '60px',
    bottom: 0,
    right: 0,
    width: '80%',
    height: 'calc(100% - 60px)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    overflowY: 'auto',
    transition: 'transform 1s ease',
    zIndex: 1301,
    transform: 'translateX(100%)',
    '&.open': {
        transform: 'translateX(0)',
    },
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "3px",
        transition: "background 0.2s ease",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}));

const Backdrop = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1300,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
}));

const SlidePanel = ({ title, children, isOpen, onClose }) => {
    return (
        <>
            <OverlayPanel className={isOpen ? 'open' : ''}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                    <Typography variant="h6">{title}</Typography>
                    <IconButton onClick={onClose}>
                        <Icon name="remove" size={20} />
                    </IconButton>
                </Box>
                {children}
            </OverlayPanel>
            {isOpen && <Backdrop onClick={onClose} />}
        </>
    );
};

SlidePanel.useSlidePanel = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const openPanel = useCallback(() => setIsOpen(true), []);
    const closePanel = useCallback(() => setIsOpen(false), []);

    return { isOpen, openPanel, closePanel };
};

export default SlidePanel;