import { createTheme } from '@mui/material/styles';
import {
	red,
	pink, 
	purple, 
	deepPurple, 
	indigo, 
	blue, 
	lightBlue, 
	cyan, 
	teal, 
	green, 
	lightGreen, 
	lime, 
	yellow, 
	amber, 
	orange, 
	deepOrange, 
	brown, 
	grey, 
	blueGrey
} from '@mui/material/colors';

const theme = createTheme({
	palette: {
		primary: {
			main: brown[400],
		},
		secondary: {
			main:  cyan[500], 
		}, 
		error: {
			main: red.A700,
		},
		warning: {
			main: lime.A700,
		},
		info: {
			main: lightBlue.A200,
		},
		success: {
			main: lime[300],
		},
	},
});

export default theme;