import React from 'react';

function EDelete2(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill} strokeLinecap="round" strokeLinejoin="round">
		<line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth={strokewidth} x1="44" x2="4" y1="24" y2="24"/>
	</g>
</svg>
	);
};

export default EDelete2;