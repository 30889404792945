import React from 'react';

function DEdit(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<path d="M16.293,23.293c-.117,.117-.203,.261-.251,.42l-3,10c-.159,.529,.142,1.086,.671,1.245,.187,.056,.387,.056,.574,0l10-3c.159-.048,.303-.134,.42-.251L41,15.414l-8.414-8.414L16.293,23.293Z" fill={secondaryfill}/>
		<path d="M39.707,1.293c-.391-.39-1.024-.39-1.414,0l-4.293,4.293,8.414,8.414,4.293-4.293c.39-.39,.39-1.023,0-1.414l-7-7Z" fill={secondaryfill}/>
		<path d="M40,27c-.553,0-1,.448-1,1v12c0,1.654-1.346,3-3,3H8c-1.654,0-3-1.346-3-3V12c0-1.654,1.346-3,3-3h12c.553,0,1-.448,1-1s-.447-1-1-1H8c-2.757,0-5,2.243-5,5v28c0,2.757,2.243,5,5,5h28c2.757,0,5-2.243,5-5v-12c0-.552-.447-1-1-1Z" fill={fill}/>
	</g>
</svg>
	);
};

export default DEdit;