import React from "react";
import { createRoot } from "react-dom/client";
import Routes from "./routes";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme/theme';

const root = createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Routes />
		</ThemeProvider>
	// </React.StrictMode>
);