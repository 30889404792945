import React, { useState } from "react";
import { TextField as MuiTextField, styled, InputAdornment, IconButton } from "@mui/material";
import Icon from "../vectors/bundle";

const StyledTextField = styled(MuiTextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        '& fieldset': {
            borderColor: '#ddd',
        },
        '&:hover fieldset': {
            borderColor: '#bbb',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#aaa',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '15px',
        fontSize: '13px',
    },
    '& .MuiInputLabel-outlined': {
        fontSize: '13px',
    },
});

export const TextField = (props) => {
    return <StyledTextField {...props} />;
};

const StyledSearchField = styled(StyledTextField)({
    '& .MuiOutlinedInput-input': {
        // paddingRight: '25px',
    },
});

export const SearchField = ({ onSearch, InputProps, ...props }) => {
    const handleSearch = () => {
        if (onSearch) {
            onSearch();
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && onSearch) {
            onSearch();
        }
    };

    return (
        <StyledSearchField
            {...props}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleSearch} edge="end">
                            <Icon name="search" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const PasswordField = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <StyledTextField
            {...props}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            <Icon name={showPassword ? "hide" : "view"} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};