import React from 'react';
import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
});

const LogoContainer = ({ name, ...props }) => (
    <StyledBox {...props}>
        <span style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <img src="/assets/resys-logo.png" alt="Logo" height="30" />
            {name && <p style={{ fontWeight: "bold", fontSize: "15px", color: "#555" }}>{name}</p>}
        </span>
    </StyledBox>
);

export default LogoContainer;