import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC0NuA9k0nLCokYYXzLwiwzEZYK8m4CN84",
    authDomain: "poolcounter-428d8.firebaseapp.com",
    projectId: "poolcounter-428d8",
    storageBucket: "poolcounter-428d8.firebasestorage.app",
    messagingSenderId: "1072837031729",
    appId: "1:1072837031729:web:199dff8fe976f1b5656210"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };